import React from 'react'
import "../../Assests/Styles/Sections/Section9.css"
import { AiFillCheckCircle } from "react-icons/ai"
import atom from "../../Assests/Images/atom.png"

const Section9 = () => {
    return (
        <section class="whoShouldAtn mb-4" style={{ background: "#fff" }}>
            <div class="container learn">
                <h2 class="mb-4">REACTJS TRAINING IS SUITABLE FOR</h2>
                <div class="row">
                    <div class="col-lg-6">
                        <ul>
                            <li><AiFillCheckCircle className='whoShouldAtnTick' />UI developers</li>
                            <li><AiFillCheckCircle className='whoShouldAtnTick' />Full Stack developers</li>
                            <li><AiFillCheckCircle className='whoShouldAtnTick' />QAs</li>
                            <li><AiFillCheckCircle className='whoShouldAtnTick' />Solution Architects</li>
                            <li><AiFillCheckCircle className='whoShouldAtnTick' />ReactJS developers looking to improve their skills</li>
                            <li><AiFillCheckCircle className='whoShouldAtnTick' />Beginners who are interested in becoming React developers</li>
                        </ul>
                    </div>
                    <div className='col-lg-6 theme-bg aos aos-init aos-animate'>
                        <img src={atom} className='reactImg'/>
                    </div>
                    <div id="curriculum"></div>
                </div>               
            </div>            
        </section>
    )
}

export default Section9
