import React, { useState } from 'react'
import "../../Assests/Styles/Sections/Section10.css"
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, theme } from 'antd';
const { Panel } = Collapse;

const Section10 = () => {
    const accordionData = [
        {
            key: "1",
            header: "Introduction to React",
            paragraph1: "Get your first hands-on experience with React and the create-react-app utility that generates a React development toolchain for an incredible developer experience.",
            paragraph2: "The app should allow users to key in text, which is reversed and displayed instantly on the UI.",
            list1: "What is React? ",
            list2: "Hello React",
            list3: "Create a React app using the create-react-app command",
            topic: "Getting hands-on:"
        },
        {
            key: "2",
            header: "Components",
            paragraph1: "Learn how to build a React application with components, the main building blocks. JSX is a syntax similar to HTML that lets you express your UI within a React component. Explore the two main types of components you can build with React, including state and event listeners.",
            paragraph2: "Create a class component that displays word and character counts as you type in a field",
            paragraph3: "Create a card component that is often seen on social media",
            paragraph4: "Develop a component that renders a list of books as a list item",
            list1: "Components: what are they?",
            list2: "Types of Components",
            list3: "JSX",
            topic: "Getting hands-on:"

        },
        {
            key: "3",
            header: "State & Props",
            paragraph1: "Learn about state which allows you to describe changes to your UI using data. You will learn the fundamentals of Hooks API, which lets you use state to implement a Function component. You will learn how to use props to pass data from a parent to a child component. You will also learn how to use type checking to debug props.",
            paragraph2: "Design a toggle button for the guidance system panel of a hypothetical spacecraft",
            paragraph3: "The Hooks API can be used to build a simple theme switcher using state",
            paragraph4: "Work on a component called UserCard that accepts data via props",
            list1: "Stateful Function Components with Hooks - what are they? ",
            list2: "Props: what are they?",
            list3: "Prop types for type checking",
            topic: "Getting hands-on:"
        },
        {
            key: "4",
            header: "Rendering Lists",
            paragraph1: "Render lists of components using the map method. Understand the importance of the 'key' attribute. Use the fragments feature to render multiple top-level components.",
            list1: "Lists can be rendered using the map function",
            list2: "The “key” attribute ",
            list3: "Using Fragments ",
            paragraph2: "Map lists of components using an array of movies and set the 'key' property ‘key’ prop",
            paragraph3: "Replace the enclosing div element with a React Component that implements the Fragment operator",
            paragraph4: "In React, we start by adding a property to the state object to render a list dynamically",
            topic: "Getting hands-on:"
        },
        {
            key: "5",
            header: "Components Revisited",
            paragraph1: "Understand the lifecycle of a component and how it can be harnessed. Learn how to integrate side effects like fetching data from an API, into a React component. Deal with side effects that require cleanup, such as using timers. Learn about React's SyntheticEvent system. Learn how to handle errors gracefully using Error Boundaries.",
            list1: "Component lifecycle ",
            list2: "Lifecycle & Side Effects",
            list3: "Management of events and errors",
            paragraph2: "Bring in data from a mock weather service using the componentDidMount lifecycle method",
            paragraph3: "To update the data, implement the componentDidUpdate lifecycle method",
            paragraph4: "To tear down an app's timer instance, the component will unmount its lifecycle function",
            paragraph5: "Set state properties using events",
            paragraph6: "Detect errors in child components with an Error Boundary component",
            topic: "Getting hands-on:"
        },
        {
            key: "6",
            header: "Render Props & Higher Order Components",
            paragraph1: "Using the HOC pattern, implement shared logic using render props.",
            list1: "Render Props",
            list2: "Components of higher order",
            list3: "Components vs. Higher Order Components",
            paragraph2: "Implement a Currency Converter component that uses render props to render the result",
            paragraph3: "Enhance our App component with tools to retrieve currency rates after conversion by building a higher order function",
            topic: "Getting hands-on:"

        },
        {
            key: "7",
            header: "Portals",
            paragraph1: "Render children in a DOM node outside of the parent's hierarchy. Understand how event bubbling works.",
            list1: "Portals: An Introduction",
            list2: "Portal events bubbling up",
            list3: "What is the best way to create a portal in a component?",
            paragraph2: "Using Portals, render a dialog box component in another DOM element than the root div of the React app",
            topic: "Getting hands-on:"

        },
        {
            key: "8",
            header: "Global & Shared Data",
            paragraph1: "Learn the nuances of data flow in a React application. Learn the issues with sharing global data using props in a deeply nested application. Discover the Context API, which lets you share global data without props.",
            list1: "Flow of unidirectional data",
            list2: "Prop challenges",
            list3: "API for Context",
            paragraph2: "To trickle data down the component hierarchy, instantiate a context and provider and consumer components",
            topic: "Getting hands-on:"
        },
        {
            key: "9",
            header: "Hooks in Focus",
            paragraph1: "To trickle data down the component hierarchy, instantiate a context and provider and consumer components",
            list1: "The useState hook ",
            list2: "The useContext hook ",
            list3: "The useReducer hook ",
            paragraph2: "Build a PowerTags component that can dynamically add/remove tags",
            paragraph3: "To fetch data from a stock app, use the useEffect function",
            paragraph4: "Consume data from a context provider using the useContext hook",
            paragraph5: "With the useReducer hook, you can offload state management and define actions and their resulting state changes",
            paragraph6: "Create a custom hook named 'useGeoLocation' that accesses the HTML5 Geolocation API to continually retrieve the user's latitude and longitude",
            topic: "Getting hands-on:"
        },
        {
            key: "10",
            header: "Routing in a React App",
            paragraph1: "Implement nested routing and use query parameters. Protect routes from unauthenticated access with Dynamic Routing & React Routers.",
            list1: "A React application's routing",
            list2: "Nested routes and parameters with React Router",
            list3: "Protecting Routes",
            paragraph2: "Using React router in a project with multiple sections",
            paragraph3: "Implement nested routing on the /books route so that details of books can be accessed",
            paragraph4: "Prevent unauthenticated access to a route",
            topic: "Getting hands-on:"
        },
        {
            key: "11",
            header: "Code Splitting",
            paragraph1: "Implement code splitting, lazy loading & suspense when working with React Router using dynamic imports & the Suspense API.",
            list1: "Suspense and code splitting",
            list2: "Splitting code based on route",
            list3: "Lazy Loading ",
            paragraph2: "Implement React.lazy and Suspense to load two components when needed ",
            paragraph3: "Implement React.lazy and Suspense when working with React Router",
            topic: "Getting hands-on:"

        },
        {
            key: "12",
            header: "Isomorphic React",
            paragraph1: "Develop a toolchain using Webpack and Babel to compile React code on the server and for clients. Learn how to setup a Node.js (Express) server for rendering React apps on the server. ",
            list1: "Server Side Rendering",
            list2: "SSR with React - Setup & Server",
            list3: "SSR with React - The Toolchain ",
        },
        {
            key: "13",
            header: "Redux State Management",
            paragraph1: "Understand why a reliable state management solution is necessary and explore Redux. Start your journey by installing and setting up Redux for use with a React app, and developing action creators & mutation logic for the Catalog component's reducer. Use the 'connect()' higher order function to connect React components with the store. As you continue, create action creators & intricate state modification instructions for the Cart component, then utilize Redux hooks and perform optimizations for enhanced speed. Additionally, explore middleware, asynchronous action creators, as well as data persistence measures.",
            list1: "Using Redux for state management and setting it up",
            list2: "Actions & Reducer for the Catalog ",
            list3: "Using Redux Hooks & Implementing Middleware & Persistence",
        },
        {
            key: "14",
            header: "Testing Components",
            paragraph1: "You will learn about the importance of testing in the development of applications. Next, you'll learn how to write unit & integration tests with Jest and test-utils from React-DOM. Finally, you'll learn about React Testing Library, the framework for testing React components.",
            list1: "Testing: An Introduction",
            list2: "React-DOM Test Utilities with Jest",
            list3: "React Testing Library & Enzyme with Jest",
        },
        {
            key: "15",
            header: "Epilogue",
            paragraph1: "Discover tools, utilities and community contributed components.",
            list1: "Ecosystem for React",
            list2: "Epilogue Syntax",
            list3: "A Declartion of Epilogue",
        },
    ]
    const [accordion, setAccordion] = useState(accordionData)

    const { token } = theme.useToken();
    const panelStyle = {
        marginBottom: 10,
        background: "white",
        border: 'none',
        fontFamily:"Kurale,serif"      
    };
    return (
        <section class="curriculum-container learn" >
            <div class="container">
                <h2 class="pt-3 pb-3 ml-3">CURRICULUM</h2>
                <div>
                    <Collapse
                        bordered={false}
                        // defaultActiveKey={[]}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        style={{
                            background: "rgba(59, 130, 216, 0.02)",
                        }}
                    >
                        {accordion.map((data) => {
                            return (
                                <Panel header={data.header} key={data.key} style={panelStyle}>
                                    <h3 className='accordionHeader'>Learning Objective :</h3>
                                    <p className='accordionPara'>{data.paragraph1}</p>
                                    <h3 className='accordionHeader' >Topics:</h3>
                                    <ul style={{ marginLeft: "20px" }} className='accordionPara'>
                                        <li >{data.list1}&nbsp;</li>
                                        <li>{data.list2}</li>
                                        <li>{data.list3}</li>
                                    </ul>
                                    <h3 className='accordionHeader mt-3' >{data.topic}</h3>
                                    <h6 className='handsonPara'>{data.paragraph2}</h6>
                                    <h6 className='handsonPara'>{data.paragraph3}</h6>
                                    <h6 className='handsonPara'>{data.paragraph4}</h6>
                                    <h6 className='handsonPara'>{data.paragraph5}</h6>
                                    <h6 className='handsonPara'>{data.paragraph6}</h6>
                                </Panel>
                            )
                        })}
                    </Collapse>
                </div>
            </div>
        </section>
    )
}

export default Section10
