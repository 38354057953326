import React, { useState, useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import "../../Assests/Styles/Sections/Section5.css"
import { BiRupee } from "react-icons/bi"
import { FaRupeeSign } from "react-icons/fa"
import ModalPage from '../ModalPage/ModalPage'
import cancel from "../../Assests/Images/slashs.png"
import {GrReactjs} from "react-icons/gr"

const Section5 = () => {
    const form = useRef();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_doyrc1k',
            'template_z931jd2',
            form.current,
            'W1HyUnWrLPukftHta'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                setShow(false);
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };
    return (
        <section id="batches" class="oracle-banner instructor-contrainer-greens mt-3">
            <div class="container">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 box_heading_section-greens">
                    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 p-0">
                        <h2 class="title_box_heading_custom">Live online ReactJS classes taught by instructors</h2>
                    </div>
                </div>
                <div class="row col-lg-12 col-md-12 col-sm-12 col-xs-12 contentWrapper">
                    <div class="col-lg-8 batch-schedule row">
                        <div class="col-lg-3">
                            <a onClick={handleShow} data-target="#exampleModal" title="Get Course Fees &amp; Discounts">
                                <div class="batch-date" style={{ overflow: "hidden" }}>
                                    <span class="day-number">
                                        <GrReactjs/>
                                    </span>
                                    <div class="date">
                                        <div class="day-text-1">Monday</div>
                                        <div class="day-text-2">Year - 2023</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3">
                            <a onClick={handleShow} data-toggle="modal" data-target="#exampleModal" title="Get Course Fees &amp; Discounts" >
                                <div class="batch-date" style={{ overflow: "hidden" }}>
                                    <span class="day-number">
                                        <GrReactjs/>
                                    </span>
                                    <div class="date">
                                        <div class="day-text-1">Tuesday</div>
                                        <div class="day-text-2">Year - 2023</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3">
                            <a onClick={handleShow} data-toggle="modal" data-target="#exampleModal" title="Get Course Fees &amp; Discounts">
                                <div class="batch-date" style={{ overflow: "hidden" }}>
                                    <span class="day-number">
                                        <GrReactjs/>
                                    </span>
                                    <div class="date">
                                        <div class="day-text-1">Wednesday</div>
                                        <div class="day-text-2">Year - 2023</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3">
                            <a onClick={handleShow} data-toggle="modal" data-target="#exampleModal" title="Get Course Fees &amp; Discounts">
                                <div class="batch-date" style={{ overflow: "hidden" }}>
                                    <span class="day-number">
                                        <GrReactjs/>
                                    </span>
                                    <div class="date">
                                        <div class="day-text-1">Thrusday</div>
                                        <div class="day-text-2">Year - 2023</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div>
                            <a class="btn btn-md btn-custom" onClick={handleShow}>
                                ENROLL NOW PAY LATER
                            </a>
                            <ModalPage
                                handleClose={handleClose}
                                show={show}
                                sendEmail={sendEmail}
                                form={form}
                            />
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-3 col-sm-12 col-xs-12 boxWrapper" id="batch_table_enroll">
                        <div class="coursePrice-detail-greens m-3">
                            <p class="title mb-0">Course Price at</p>
                            <div class="course-price">
                                <div className='d-flex flex-column cancelWithText'>
                                    <div className='cancel'>
                                        <img src={cancel} className='cancelbtn'/>
                                    </div>
                                    <div className='rup'>
                                        <span class="discount-price price_16092">
                                            <i class="fa fa-inr" aria-hidden="true">
                                                <BiRupee />
                                            </i>
                                        </span>
                                        <div className='rupees'>25,000</div>
                                    </div>
                                </div>
                                <div className='d-flex flex-column px-4'>
                                    <span class="final-price discount_16092">
                                        <i class="fa fa-inr" aria-hidden="true">
                                            <FaRupeeSign />
                                        </i>
                                    </span>
                                    <div className='rupees1'>18,000</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='prerequisites'></div>
            </div>
        </section>
    )
}

export default Section5
