import React,{useRef} from 'react'
import test1 from "../../Assests/Images/test1.mp4"
import test2 from "../../Assests/Images/test2.mp4"
import test3 from "../../Assests/Images/test3.mp4"
import ReactPlayer from "react-player";
import p1 from "../../Assests/Images/prasanath.jpg"
import p2 from "../../Assests/Images/hasrin.jpg"
import p3 from "../../Assests/Images/jayanth.jpg"

const Section18 = () => {
    const playerRef = useRef(null);
    return (
        <section id="" class="course-body">
            <div class="container mb-4">
                <h2 class="pb-4 pt-3 m-0 featuresTitle">Students Testimonials</h2>
                <div className='col-12 d-flex flex-md-row flex-column mt-3'>
                    <div className='col-md-4 col-12'>
                        <ReactPlayer
                            width="100%"
                            height="30vh"
                            url={test1}
                            controls={true}
                            ref={playerRef}
                            light={p3}
                        />
                    </div>
                    <div className='col-md-4 col-12'>
                    <ReactPlayer
                            width="100%"
                            height="30vh"
                            url={test2}
                            controls={true}
                            ref={playerRef}
                            light={p2}
                        />
                    </div>
                    <div className='col-md-4 col-12'>
                    <ReactPlayer
                            width="100%"
                            height="30vh"
                            url={test3}
                            controls={true}
                            ref={playerRef}
                            light={p1}
                        />
                    </div>
                </div>
            </div >
            <div id="promo-container"></div>
        </section >
    )
}

export default Section18
