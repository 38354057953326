import React from 'react'
import "../../Assests/Styles/Sections/Section2.css"
import { GiFlowerStar } from "react-icons/gi"
import bannerImg from "../../Assests/Images/bannerimg.png"

const Section2 = () => {
    return (
        <section id="whyreact">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-12 col-md-6 mx-auto">
                        <div class="pt-3">
                            <h2 style={{ fontSize: "22px",fontFamily: "'Kurale', serif" }} class="pb-3 pt-4 m-0">
                                <strong>WHAT YOU WILL LEARN ?</strong></h2>
                            <ul class="course-desc">
                                <li><GiFlowerStar className='expand' /> Develop your skills as a Frontend Engineer / Developer</li>
                                <li><GiFlowerStar className='expand' /> Utilize reusable components to build composable user interfaces</li>
                                <li><GiFlowerStar className='expand' /> Develop web applications that are performant, interactive, and data-driven</li>
                                <li><GiFlowerStar className='expand' /> Create Single Page Apps (SPAs)</li>
                                <li><GiFlowerStar className='expand' /> Install and use the create-react-app toolchain</li>
                                <li><GiFlowerStar className='expand' /> Develop dynamic components that interact with APIs, services, and the backend</li>
                                <li><GiFlowerStar className='expand' /> Develop reusable and shared logic for your application ecosystem</li>
                                <li><GiFlowerStar className='expand' /> Create interactive forms</li>
                                <li><GiFlowerStar className='expand' /> Use React Router to implement routing in single-page React applications</li>
                                <li><GiFlowerStar className='expand' /> Create isomorphic React applications</li>
                                <li><GiFlowerStar className='expand' /> Use Redux and middleware such as redux-thunk and React Saga (Optional)</li>
                                <li><GiFlowerStar className='expand' /> Using Jest, React Testing Library, and Enzyme, test React components and applications</li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-6  col-md-6 pt-5 col-12 mt-5 '>
                        <img src={bannerImg} className='img-fluid'/>
                    </div>
                </div>
            </div></section>
    )
}

export default Section2
