import React, { useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import "../../Assests/Styles/Sections/Section8.css"
import $ from "jquery"

const Section8 = () => {
	const phoneNumber = [
		{
			value:"",
			dataIndex :"",
			dataPhoneCode:"",
			dataCountryId:"",
			dataCountryName:""
		}
	]
	$(document).on("change", ".number_prefix", function () {
		$('.prefix_code').text($('.number_prefix option:selected').attr('data-phone-code'));
	});
	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();
		console.log("Sending Mail....");
		emailjs.sendForm(
			'service_doyrc1k',
			'template_z931jd2',
			form.current,
			'W1HyUnWrLPukftHta'
		)
			.then((result) => {
				console.log(result.text);
				toast.success("Message Sent Successfully! We will get back to you soon.");
				e.target.reset();
				console.log("message sent");
			}, (error) => {
				toast.error("Message Sending Failed! Please try again.")
				console.log(error.text);
			});
	};
	return (
		<section class="mid_enroll_bx " style={{ background: "#fff" }}>
			<div class="container">
				<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 demoForm loaded pl-4 pt-4">
					<div class="title">Want to Learn more?</div>
					<div class="sub">Register for FREE demo on this Course</div>
					<form class="curriculam-form" ref={form} onSubmit={sendEmail}>
						<div class="form-group">
							{/* <div class="country_mob_prefix">
								<div class="country-code">
									<select class="number_prefix" aria-label="prefix">
										<option value="IN" data-index="0" data-phone-code="+91" data-country-id="12" data-country-name="India">IN  -  India</option>
										<option value="US" data-index="1" data-phone-code="+1" data-country-id="2" data-country-name="US (CST)">US  -  US (CST)</option>
										<option value="US" data-index="2" data-phone-code="+1" data-country-id="3" data-country-name="US (MST)">US  -  US (MST)</option>
										<option value="US" data-index="3" data-phone-code="+1" data-country-id="5" data-country-name="US (PST)">US  -  US (PST)</option>
										<option value="GB" data-index="4" data-phone-code="+44" data-country-id="149" data-country-name="United Kingdom">GB  -  United Kingdom</option>
										<option value="SG" data-index="5" data-phone-code="+65" data-country-id="10" data-country-name="Singapore">SG  -  Singapore</option>
										<option value="CA" data-index="6" data-phone-code="+1" data-country-id="11" data-country-name="Canada">CA  -  Canada</option>
										<option value="IN" data-index="7" data-phone-code="+91" data-country-id="12" data-country-name="India">IN  -  India</option>
										<option value="AE" data-index="8" data-phone-code="+971" data-country-id="80" data-country-name="United Arab Emirates">AE  -  United Arab Emirates</option>
										<option value="AU" data-index="9" data-phone-code="+61" data-country-id="13" data-country-name="Australia">AU  -  Australia</option>
										<option value="AF" data-index="10" data-phone-code="+93" data-country-id="77" data-country-name="Afghanistan">AF  -  Afghanistan</option>
										<option value="AX" data-index="11" data-phone-code="+358" data-country-id="94" data-country-name="Aland Islands">AX  -  Aland Islands</option>
										<option value="AL" data-index="12" data-phone-code="+355" data-country-id="101" data-country-name="Albania">AL  -  Albania</option>
										<option value="DZ" data-index="13" data-phone-code="+213" data-country-id="106" data-country-name="Algeria">DZ  -  Algeria</option>
										<option value="AS" data-index="14" data-phone-code="+1684" data-country-id="98" data-country-name="American Samoa">AS  -  American Samoa</option>
										<option value="AD" data-index="15" data-phone-code="+376" data-country-id="79" data-country-name="Andorra">AD  -  Andorra</option>
										<option value="AO" data-index="16" data-phone-code="+244" data-country-id="100" data-country-name="Angola">AO  -  Angola</option>
										<option value="AI" data-index="17" data-phone-code="+1264" data-country-id="76" data-country-name="Anguilla">AI  -  Anguilla</option>
										<option value="AQ" data-index="18" data-phone-code="+672" data-country-id="99" data-country-name="Antarctica">AQ  -  Antarctica</option>
										<option value="AG" data-index="19" data-phone-code="+1268" data-country-id="78" data-country-name="Antigua and Barbuda">AG  -  Antigua and Barbuda</option>
										<option value="AR" data-index="20" data-phone-code="+54" data-country-id="97" data-country-name="Argentina">AR  -  Argentina</option>
										<option value="AM" data-index="21" data-phone-code="+374" data-country-id="88" data-country-name="Armenia">AM  -  Armenia</option>
										<option value="AW" data-index="22" data-phone-code="+297" data-country-id="95" data-country-name="Aruba">AW  -  Aruba</option>
										<option value="AT" data-index="23" data-phone-code="+43" data-country-id="96" data-country-name="Austria">AT  -  Austria</option>
										<option value="AZ" data-index="24" data-phone-code="+994" data-country-id="93" data-country-name="Azerbaijan">AZ  -  Azerbaijan</option>
										<option value="BS" data-index="25" data-phone-code="+1242" data-country-id="188" data-country-name="Bahamas">BS  -  Bahamas</option>
										<option value="BH" data-index="26" data-phone-code="+973" data-country-id="167" data-country-name="Bahrain">BH  -  Bahrain</option>
										<option value="BD" data-index="27" data-phone-code="+880" data-country-id="193" data-country-name="Bangladesh">BD  -  Bangladesh</option>
										<option value="BB" data-index="28" data-phone-code="+1-246" data-country-id="173" data-country-name="Barbados">BB  -  Barbados</option>
										<option value="BY" data-index="29" data-phone-code="+375" data-country-id="186" data-country-name="Belarus">BY  -  Belarus</option>
										<option value="BE" data-index="30" data-phone-code="+32" data-country-id="177" data-country-name="Belgium">BE  -  Belgium</option>
										<option value="BZ" data-index="31" data-phone-code="+501" data-country-id="185" data-country-name="Belize">BZ  -  Belize</option>
										<option value="BJ" data-index="32" data-phone-code="+229" data-country-id="165" data-country-name="Benin">BJ  -  Benin</option>
										<option value="BM" data-index="33" data-phone-code="+1441" data-country-id="170" data-country-name="Bermuda">BM  -  Bermuda</option>
										<option value="BT" data-index="34" data-phone-code="+975" data-country-id="178" data-country-name="Bhutan">BT  -  Bhutan</option>
										<option value="BO" data-index="35" data-phone-code="+591" data-country-id="168" data-country-name="Bolivia">BO  -  Bolivia</option>
										<option value="BW" data-index="36" data-phone-code="+267" data-country-id="192" data-country-name="Botswana">BW  -  Botswana</option>
										<option value="BR" data-index="37" data-phone-code="+55" data-country-id="189" data-country-name="Brazil">BR  -  Brazil</option>
										<option value="VG" data-index="38" data-phone-code="+1284" data-country-id="109" data-country-name="British Virgin Islands">VG  -  British Virgin Islands</option>
										<option value="BN" data-index="39" data-phone-code="+673" data-country-id="169" data-country-name="Brunei">BN  -  Brunei</option>
										<option value="BG" data-index="40" data-phone-code="+359" data-country-id="175" data-country-name="Bulgaria">BG  -  Bulgaria</option>
										<option value="BF" data-index="41" data-phone-code="+226" data-country-id="176" data-country-name="Burkina Faso">BF  -  Burkina Faso</option>
										<option value="BI" data-index="42" data-phone-code="+257" data-country-id="166" data-country-name="Burundi">BI  -  Burundi</option>
										<option value="KH" data-index="43" data-phone-code="+855" data-country-id="39" data-country-name="Cambodia">KH  -  Cambodia</option>
										<option value="CM" data-index="44" data-phone-code="+237" data-country-id="62" data-country-name="Cameroon">CM  -  Cameroon</option>
										<option value="CV" data-index="45" data-phone-code="+238" data-country-id="21" data-country-name="Cape Verde">CV  -  Cape Verde</option>
										<option value="KY" data-index="46" data-phone-code="+1345" data-country-id="132" data-country-name="Cayman Islands">KY  -  Cayman Islands</option>
										<option value="CF" data-index="47" data-phone-code="+236" data-country-id="43" data-country-name="Central African Republic">CF  -  Central African Republic</option>
										<option value="TD" data-index="48" data-phone-code="+235" data-country-id="85" data-country-name="Chad">TD  -  Chad</option>
										<option value="CL" data-index="49" data-phone-code="+56" data-country-id="61" data-country-name="Chile">CL  -  Chile</option>
										<option value="CN" data-index="50" data-phone-code="+86" data-country-id="63" data-country-name="China">CN  -  China</option>
										<option value="CC" data-index="51" data-phone-code="+61" data-country-id="60" data-country-name="Cocos Islands">CC  -  Cocos Islands</option>
										<option value="CO" data-index="52" data-phone-code="+57" data-country-id="64" data-country-name="Colombia">CO  -  Colombia</option>
										<option value="KM" data-index="53" data-phone-code="+269" data-country-id="37" data-country-name="Comoros">KM  -  Comoros</option>
										<option value="CK" data-index="54" data-phone-code="+682" data-country-id="67" data-country-name="Cook Islands">CK  -  Cook Islands</option>
										<option value="CR" data-index="55" data-phone-code="+506" data-country-id="23" data-country-name="Costa Rica">CR  -  Costa Rica</option>
										<option value="HR" data-index="56" data-phone-code="+385" data-country-id="152" data-country-name="Croatia">HR  -  Croatia</option>
										<option value="CU" data-index="57" data-phone-code="+53" data-country-id="20" data-country-name="Cuba">CU  -  Cuba</option>
										<option value="CW" data-index="58" data-phone-code="+599" data-country-id="22" data-country-name="Curacao">CW  -  Curacao</option>
										<option value="CY" data-index="59" data-phone-code="+357" data-country-id="25" data-country-name="Cyprus">CY  -  Cyprus</option>
										<option value="CZ" data-index="60" data-phone-code="+420" data-country-id="41" data-country-name="Czech Republic">CZ  -  Czech Republic</option>
										<option value="DK" data-index="61" data-phone-code="+45" data-country-id="110" data-country-name="Denmark">DK  -  Denmark</option>
										<option value="DJ" data-index="62" data-phone-code="+253" data-country-id="111" data-country-name="Djibouti">DJ  -  Djibouti</option>
										<option value="DM" data-index="63" data-phone-code="+1767" data-country-id="112" data-country-name="Dominica">DM  -  Dominica</option>
										<option value="DO" data-index="64" data-phone-code="+1849" data-country-id="113" data-country-name="Dominican Republic">DO  -  Dominican Republic</option>
										<option value="TL" data-index="65" data-phone-code="+670" data-country-id="181" data-country-name="East Timor">TL  -  East Timor</option>
										<option value="EC" data-index="66" data-phone-code="+593" data-country-id="249" data-country-name="Ecuador">EC  -  Ecuador</option>
										<option value="EG" data-index="67" data-phone-code="+20" data-country-id="251" data-country-name="Egypt">EG  -  Egypt</option>
										<option value="SV" data-index="68" data-phone-code="+503" data-country-id="161" data-country-name="El Salvador">SV  -  El Salvador</option>
										<option value="GQ" data-index="69" data-phone-code="+240" data-country-id="139" data-country-name="Equatorial Guinea">GQ  -  Equatorial Guinea</option>
										<option value="ER" data-index="70" data-phone-code="+291" data-country-id="208" data-country-name="Eritrea">ER  -  Eritrea</option>
										<option value="EE" data-index="71" data-phone-code="+372" data-country-id="252" data-country-name="Estonia">EE  -  Estonia</option>
										<option value="ET" data-index="72" data-phone-code="+251" data-country-id="245" data-country-name="Ethiopia">ET  -  Ethiopia</option>
										<option value="FK" data-index="73" data-phone-code="+500" data-country-id="54" data-country-name="Falkland Islands">FK  -  Falkland Islands</option>
										<option value="FO" data-index="74" data-phone-code="+298" data-country-id="52" data-country-name="Faroe Islands">FO  -  Faroe Islands</option>
										<option value="FJ" data-index="75" data-phone-code="+679" data-country-id="55" data-country-name="Fiji">FJ  -  Fiji</option>
										<option value="FI" data-index="76" data-phone-code="+358" data-country-id="56" data-country-name="Finland">FI  -  Finland</option>
										<option value="FR" data-index="77" data-phone-code="+33" data-country-id="212" data-country-name="France">FR  -  France</option>
										<option value="GF" data-index="78" data-phone-code="+594" data-country-id="134" data-country-name="French Guiana">GF  -  French Guiana</option>
										<option value="PF" data-index="79" data-phone-code="+689" data-country-id="230" data-country-name="French Polynesia">PF  -  French Polynesia</option>
										<option value="GA" data-index="80" data-phone-code="+241" data-country-id="162" data-country-name="Gabon">GA  -  Gabon</option>
										<option value="GM" data-index="81" data-phone-code="+220" data-country-id="159" data-country-name="Gambia">GM  -  Gambia</option>
										<option value="GE" data-index="82" data-phone-code="+995" data-country-id="147" data-country-name="Georgia">GE  -  Georgia</option>
										<option value="DE" data-index="83" data-phone-code="+49" data-country-id="108" data-country-name="Germany">DE  -  Germany</option>
										<option value="GH" data-index="84" data-phone-code="+233" data-country-id="156" data-country-name="Ghana">GH  -  Ghana</option>
										<option value="GI" data-index="85" data-phone-code="+350" data-country-id="157" data-country-name="Gibraltar">GI  -  Gibraltar</option>
										<option value="GR" data-index="86" data-phone-code="+30" data-country-id="140" data-country-name="Greece">GR  -  Greece</option>
										<option value="GL" data-index="87" data-phone-code="+299" data-country-id="158" data-country-name="Greenland">GL  -  Greenland</option>
										<option value="GD" data-index="88" data-phone-code="+1473" data-country-id="148" data-country-name="Grenada">GD  -  Grenada</option>
										<option value="GP" data-index="89" data-phone-code="+590" data-country-id="138" data-country-name="Guadeloupe">GP  -  Guadeloupe</option>
										<option value="GU" data-index="90" data-phone-code="+1671" data-country-id="143" data-country-name="Guam">GU  -  Guam</option>
										<option value="GT" data-index="91" data-phone-code="+502" data-country-id="142" data-country-name="Guatemala">GT  -  Guatemala</option>
										<option value="GG" data-index="92" data-phone-code="+44" data-country-id="135" data-country-name="Guernsey">GG  -  Guernsey</option>
										<option value="GN" data-index="93" data-phone-code="+224" data-country-id="160" data-country-name="Guinea">GN  -  Guinea</option>
										<option value="GW" data-index="94" data-phone-code="+245" data-country-id="144" data-country-name="Guinea-Bissau">GW  -  Guinea-Bissau</option>
										<option value="GY" data-index="95" data-phone-code="+592" data-country-id="136" data-country-name="Guyana">GY  -  Guyana</option>
										<option value="HT" data-index="96" data-phone-code="+509" data-country-id="151" data-country-name="Haiti">HT  -  Haiti</option>
										<option value="HN" data-index="97" data-phone-code="+504" data-country-id="194" data-country-name="Honduras">HN  -  Honduras</option>
										<option value="HK" data-index="98" data-phone-code="+852" data-country-id="133" data-country-name="Hong Kong">HK  -  Hong Kong</option>
										<option value="HU" data-index="99" data-phone-code="+36" data-country-id="150" data-country-name="Hungary">HU  -  Hungary</option>
										<option value="IS" data-index="100" data-phone-code="+354" data-country-id="74" data-country-name="Iceland">IS  -  Iceland</option>
										<option value="ID" data-index="101" data-phone-code="+62" data-country-id="91" data-country-name="Indonesia">ID  -  Indonesia</option>
										<option value="IR" data-index="102" data-phone-code="+98" data-country-id="87" data-country-name="Iran">IR  -  Iran</option>
										<option value="IQ" data-index="103" data-phone-code="+964" data-country-id="232" data-country-name="Iraq">IQ  -  Iraq</option>
										<option value="IE" data-index="104" data-phone-code="+353" data-country-id="92" data-country-name="Ireland">IE  -  Ireland</option>
										<option value="IM" data-index="105" data-phone-code="+44" data-country-id="218" data-country-name="Isle of Man">IM  -  Isle of Man</option>
										<option value="IL" data-index="106" data-phone-code="+972" data-country-id="213" data-country-name="Israel">IL  -  Israel</option>
										<option value="IT" data-index="107" data-phone-code="+39" data-country-id="248" data-country-name="Italy">IT  -  Italy</option>
										<option value="CI" data-index="108" data-phone-code="+225" data-country-id="66" data-country-name="Ivory Coast">CI  -  Ivory Coast</option>
										<option value="JM" data-index="109" data-phone-code="+1876" data-country-id="179" data-country-name="Jamaica">JM  -  Jamaica</option>
										<option value="JP" data-index="110" data-phone-code="+81" data-country-id="137" data-country-name="Japan">JP  -  Japan</option>
										<option value="JE" data-index="111" data-phone-code="+44" data-country-id="187" data-country-name="Jersey">JE  -  Jersey</option>
										<option value="JO" data-index="112" data-phone-code="+962" data-country-id="153" data-country-name="Jordan">JO  -  Jordan</option>
										<option value="KZ" data-index="113" data-phone-code="+7" data-country-id="72" data-country-name="Kazakhstan">KZ  -  Kazakhstan</option>
										<option value="KE" data-index="114" data-phone-code="+254" data-country-id="14" data-country-name="Kenya">KE  -  Kenya</option>
										<option value="KI" data-index="115" data-phone-code="+686" data-country-id="40" data-country-name="Kiribati">KI  -  Kiribati</option>
										<option value="KW" data-index="116" data-phone-code="+965" data-country-id="31" data-country-name="Kuwait">KW  -  Kuwait</option>
										<option value="KG" data-index="117" data-phone-code="+996" data-country-id="18" data-country-name="Kyrgyzstan">KG  -  Kyrgyzstan</option>
										<option value="LA" data-index="118" data-phone-code="+856" data-country-id="131" data-country-name="Laos">LA  -  Laos</option>
										<option value="LV" data-index="119" data-phone-code="+371" data-country-id="124" data-country-name="Latvia">LV  -  Latvia</option>
										<option value="LB" data-index="120" data-phone-code="+961" data-country-id="116" data-country-name="Lebanon">LB  -  Lebanon</option>
										<option value="LS" data-index="121" data-phone-code="+266" data-country-id="119" data-country-name="Lesotho">LS  -  Lesotho</option>
										<option value="LR" data-index="122" data-phone-code="+231" data-country-id="120" data-country-name="Liberia">LR  -  Liberia</option>
										<option value="LY" data-index="123" data-phone-code="+218" data-country-id="83" data-country-name="Libya">LY  -  Libya</option>
										<option value="LI" data-index="124" data-phone-code="+423" data-country-id="125" data-country-name="Liechtenstein">LI  -  Liechtenstein</option>
										<option value="LT" data-index="125" data-phone-code="+370" data-country-id="122" data-country-name="Lithuania">LT  -  Lithuania</option>
										<option value="LU" data-index="126" data-phone-code="+352" data-country-id="121" data-country-name="Luxembourg">LU  -  Luxembourg</option>
										<option value="MO" data-index="127" data-phone-code="+853" data-country-id="198" data-country-name="Macao">MO  -  Macao</option>
										<option value="MK" data-index="128" data-phone-code="+389" data-country-id="209" data-country-name="Macedonia">MK  -  Macedonia</option>
										<option value="MG" data-index="129" data-phone-code="+261" data-country-id="205" data-country-name="Madagascar">MG  -  Madagascar</option>
										<option value="MW" data-index="130" data-phone-code="+265" data-country-id="224" data-country-name="Malawi">MW  -  Malawi</option>
										<option value="MY" data-index="131" data-phone-code="+60" data-country-id="215" data-country-name="Malaysia">MY  -  Malaysia</option>
										<option value="MV" data-index="132" data-phone-code="+960" data-country-id="223" data-country-name="Maldives">MV  -  Maldives</option>
										<option value="ML" data-index="133" data-phone-code="+223" data-country-id="199" data-country-name="Mali">ML  -  Mali</option>
										<option value="MT" data-index="134" data-phone-code="+356" data-country-id="211" data-country-name="Malta">MT  -  Malta</option>
										<option value="MH" data-index="135" data-phone-code="+692" data-country-id="196" data-country-name="Marshall Islands">MH  -  Marshall Islands</option>
										<option value="MQ" data-index="136" data-phone-code="+596" data-country-id="222" data-country-name="Martinique">MQ  -  Martinique</option>
										<option value="MR" data-index="137" data-phone-code="+222" data-country-id="219" data-country-name="Mauritania">MR  -  Mauritania</option>
										<option value="MU" data-index="138" data-phone-code="+230" data-country-id="210" data-country-name="Mauritius">MU  -  Mauritius</option>
										<option value="YT" data-index="139" data-phone-code="+262" data-country-id="104" data-country-name="Mayotte">YT  -  Mayotte</option>
										<option value="MX" data-index="140" data-phone-code="+52" data-country-id="214" data-country-name="Mexico">MX  -  Mexico</option>
										<option value="FM" data-index="141" data-phone-code="+691" data-country-id="53" data-country-name="Micronesia">FM  -  Micronesia</option>
										<option value="MD" data-index="142" data-phone-code="+373" data-country-id="206" data-country-name="Moldova">MD  -  Moldova</option>
										<option value="MC" data-index="143" data-phone-code="+377" data-country-id="202" data-country-name="Monaco">MC  -  Monaco</option>
										<option value="MN" data-index="144" data-phone-code="+976" data-country-id="197" data-country-name="Mongolia">MN  -  Mongolia</option>
										<option value="ME" data-index="145" data-phone-code="+382" data-country-id="207" data-country-name="Montenegro">ME  -  Montenegro</option>
										<option value="MS" data-index="146" data-phone-code="+1" data-country-id="220" data-country-name="Montserrat">MS  -  Montserrat</option>
										<option value="MA" data-index="147" data-phone-code="+212" data-country-id="203" data-country-name="Morocco">MA  -  Morocco</option>
										<option value="MZ" data-index="148" data-phone-code="+258" data-country-id="73" data-country-name="Mozambique">MZ  -  Mozambique</option>
										<option value="MM" data-index="149" data-phone-code="+95" data-country-id="200" data-country-name="Myanmar">MM  -  Myanmar</option>
										<option value="NA" data-index="150" data-phone-code="+264" data-country-id="48" data-country-name="Namibia">NA  -  Namibia</option>
										<option value="NR" data-index="151" data-phone-code="+674" data-country-id="69" data-country-name="Nauru">NR  -  Nauru</option>
										<option value="NP" data-index="152" data-phone-code="+977" data-country-id="70" data-country-name="Nepal">NP  -  Nepal</option>
										<option value="NL" data-index="153" data-phone-code="+31" data-country-id="50" data-country-name="Netherlands">NL  -  Netherlands</option>
										<option value="NC" data-index="154" data-phone-code="+687" data-country-id="46" data-country-name="New Caledonia">NC  -  New Caledonia</option>
										<option value="NZ" data-index="155" data-phone-code="+64" data-country-id="58" data-country-name="New Zealand">NZ  -  New Zealand</option>
										<option value="NI" data-index="156" data-phone-code="+505" data-country-id="51" data-country-name="Nicaragua">NI  -  Nicaragua</option>
										<option value="NE" data-index="157" data-phone-code="+227" data-country-id="45" data-country-name="Niger">NE  -  Niger</option>
										<option value="NG" data-index="158" data-phone-code="+234" data-country-id="57" data-country-name="Nigeria">NG  -  Nigeria</option>
										<option value="NU" data-index="159" data-phone-code="+683" data-country-id="68" data-country-name="Niue">NU  -  Niue</option>
										<option value="NF" data-index="160" data-phone-code="+672" data-country-id="44" data-country-name="Norfolk Island">NF  -  Norfolk Island</option>
										<option value="KP" data-index="161" data-phone-code="+850" data-country-id="32" data-country-name="North Korea">KP  -  North Korea</option>
										<option value="NO" data-index="162" data-phone-code="+47" data-country-id="49" data-country-name="Norway">NO  -  Norway</option>
										<option value="OM" data-index="163" data-phone-code="+968" data-country-id="155" data-country-name="Oman">OM  -  Oman</option>
										<option value="PK" data-index="164" data-phone-code="+92" data-country-id="227" data-country-name="Pakistan">PK  -  Pakistan</option>
										<option value="PW" data-index="165" data-phone-code="+680" data-country-id="236" data-country-name="Palau">PW  -  Palau</option>
										<option value="PS" data-index="166" data-phone-code="+970" data-country-id="237" data-country-name="Palestinian Territory">PS  -  Palestinian Territory</option>
										<option value="PA" data-index="167" data-phone-code="+507" data-country-id="231" data-country-name="Panama">PA  -  Panama</option>
										<option value="PG" data-index="168" data-phone-code="+675" data-country-id="229" data-country-name="Papua New Guinea">PG  -  Papua New Guinea</option>
										<option value="PY" data-index="169" data-phone-code="+595" data-country-id="233" data-country-name="Paraguay">PY  -  Paraguay</option>
										<option value="PE" data-index="170" data-phone-code="+51" data-country-id="228" data-country-name="Peru">PE  -  Peru</option>
										<option value="PH" data-index="171" data-phone-code="+63" data-country-id="240" data-country-name="Philippines">PH  -  Philippines</option>
										<option value="PN" data-index="172" data-phone-code="+64" data-country-id="241" data-country-name="Pitcairn">PN  -  Pitcairn</option>
										<option value="PL" data-index="173" data-phone-code="+48" data-country-id="242" data-country-name="Poland">PL  -  Poland</option>
										<option value="PT" data-index="174" data-phone-code="+351" data-country-id="235" data-country-name="Portugal">PT  -  Portugal</option>
										<option value="PR" data-index="175" data-phone-code="+1" data-country-id="238" data-country-name="Puerto Rico">PR  -  Puerto Rico</option>
										<option value="QA" data-index="176" data-phone-code="+974" data-country-id="89" data-country-name="Qatar">QA  -  Qatar</option>
										<option value="CG" data-index="177" data-phone-code="+242" data-country-id="59" data-country-name="Republic of the Congo">CG  -  Republic of the Congo</option>
										<option value="RE" data-index="178" data-phone-code="+262" data-country-id="180" data-country-name="Reunion">RE  -  Reunion</option>
										<option value="RO" data-index="179" data-phone-code="+40" data-country-id="146" data-country-name="Romania">RO  -  Romania</option>
										<option value="RU" data-index="180" data-phone-code="+7" data-country-id="184" data-country-name="Russia">RU  -  Russia</option>
										<option value="RW" data-index="181" data-phone-code="+250" data-country-id="183" data-country-name="Rwanda">RW  -  Rwanda</option>
										<option value="BL" data-index="182" data-phone-code="+590" data-country-id="171" data-country-name="Saint Barthelemy">BL  -  Saint Barthelemy</option>
										<option value="SH" data-index="183" data-phone-code="+290" data-country-id="71" data-country-name="Saint Helena">SH  -  Saint Helena</option>
										<option value="LC" data-index="184" data-phone-code="+1" data-country-id="118" data-country-name="Saint Lucia">LC  -  Saint Lucia</option>
										<option value="MF" data-index="185" data-phone-code="+590" data-country-id="204" data-country-name="Saint Martin">MF  -  Saint Martin</option>
										<option value="WS" data-index="186" data-phone-code="+685" data-country-id="191" data-country-name="Samoa">WS  -  Samoa</option>
										<option value="SM" data-index="187" data-phone-code="+378" data-country-id="29" data-country-name="San Marino">SM  -  San Marino</option>
										<option value="SA" data-index="188" data-phone-code="+966" data-country-id="226" data-country-name="Saudi Arabia">SA  -  Saudi Arabia</option>
										<option value="SN" data-index="189" data-phone-code="+221" data-country-id="30" data-country-name="Senegal">SN  -  Senegal</option>
										<option value="RS" data-index="190" data-phone-code="+381" data-country-id="182" data-country-name="Serbia">RS  -  Serbia</option>
										<option value="SC" data-index="191" data-phone-code="+248" data-country-id="26" data-country-name="Seychelles">SC  -  Seychelles</option>
										<option value="SL" data-index="192" data-phone-code="+232" data-country-id="28" data-country-name="Sierra Leone">SL  -  Sierra Leone</option>
										<option value="SX" data-index="193" data-phone-code="+1721" data-country-id="15" data-country-name="Sint Maarten">SX  -  Sint Maarten</option>
										<option value="SK" data-index="194" data-phone-code="+421" data-country-id="35" data-country-name="Slovakia">SK  -  Slovakia</option>
										<option value="SI" data-index="195" data-phone-code="+386" data-country-id="33" data-country-name="Slovenia">SI  -  Slovenia</option>
										<option value="SB" data-index="196" data-phone-code="+677" data-country-id="246" data-country-name="Solomon Islands">SB  -  Solomon Islands</option>
										<option value="SO" data-index="197" data-phone-code="+252" data-country-id="244" data-country-name="Somalia">SO  -  Somalia</option>
										<option value="ZA" data-index="198" data-phone-code="+27" data-country-id="250" data-country-name="South Africa">ZA  -  South Africa</option>
										<option value="KR" data-index="199" data-phone-code="+82" data-country-id="34" data-country-name="South Korea">KR  -  South Korea</option>
										<option value="SS" data-index="200" data-phone-code="+211" data-country-id="16" data-country-name="South Sudan">SS  -  South Sudan</option>
										<option value="ES" data-index="201" data-phone-code="+34" data-country-id="225" data-country-name="Spain">ES  -  Spain</option>
										<option value="LK" data-index="202" data-phone-code="+94" data-country-id="126" data-country-name="Sri Lanka">LK  -  Sri Lanka</option>
										<option value="SD" data-index="203" data-phone-code="+249" data-country-id="114" data-country-name="Sudan">SD  -  Sudan</option>
										<option value="SR" data-index="204" data-phone-code="+597" data-country-id="27" data-country-name="Suriname">SR  -  Suriname</option>
										<option value="SJ" data-index="205" data-phone-code="+47" data-country-id="234" data-country-name="Svalbard and Jan Mayen">SJ  -  Svalbard and Jan Mayen</option>
										<option value="SZ" data-index="206" data-phone-code="+268" data-country-id="19" data-country-name="Swaziland">SZ  -  Swaziland</option>
										<option value="SE" data-index="207" data-phone-code="+46" data-country-id="115" data-country-name="Sweden">SE  -  Sweden</option>
										<option value="CH" data-index="208" data-phone-code="+41" data-country-id="65" data-country-name="Switzerland">CH  -  Switzerland</option>
										<option value="SY" data-index="209" data-phone-code="+963" data-country-id="17" data-country-name="Syria">SY  -  Syria</option>
										<option value="TW" data-index="210" data-phone-code="+886" data-country-id="129" data-country-name="Taiwan">TW  -  Taiwan</option>
										<option value="TJ" data-index="211" data-phone-code="+992" data-country-id="163" data-country-name="Tajikistan">TJ  -  Tajikistan</option>
										<option value="TZ" data-index="212" data-phone-code="+255" data-country-id="216" data-country-name="Tanzania">TZ  -  Tanzania</option>
										<option value="TH" data-index="213" data-phone-code="+66" data-country-id="103" data-country-name="Thailand">TH  -  Thailand</option>
										<option value="TG" data-index="214" data-phone-code="+228" data-country-id="86" data-country-name="Togo">TG  -  Togo</option>
										<option value="TK" data-index="215" data-phone-code="+690" data-country-id="145" data-country-name="Tokelau">TK  -  Tokelau</option>
										<option value="TO" data-index="216" data-phone-code="+676" data-country-id="123" data-country-name="Tonga">TO  -  Tonga</option>
										<option value="TT" data-index="217" data-phone-code="+1868" data-country-id="128" data-country-name="Trinidad and Tobago">TT  -  Trinidad and Tobago</option>
										<option value="TN" data-index="218" data-phone-code="+216" data-country-id="154" data-country-name="Tunisia">TN  -  Tunisia</option>
										<option value="TR" data-index="219" data-phone-code="+90" data-country-id="127" data-country-name="Turkey">TR  -  Turkey</option>
										<option value="TM" data-index="220" data-phone-code="+993" data-country-id="164" data-country-name="Turkmenistan">TM  -  Turkmenistan</option>
										<option value="TV" data-index="221" data-phone-code="+688" data-country-id="130" data-country-name="Tuvalu">TV  -  Tuvalu</option>
										<option value="VI" data-index="222" data-phone-code="+1" data-country-id="75" data-country-name="U.S. Virgin Islands">VI  -  U.S. Virgin Islands</option>
										<option value="UG" data-index="223" data-phone-code="+256" data-country-id="217" data-country-name="Uganda">UG  -  Uganda</option>
										<option value="UA" data-index="224" data-phone-code="+380" data-country-id="90" data-country-name="Ukraine">UA  -  Ukraine</option>
										<option value="UY" data-index="225" data-phone-code="+598" data-country-id="105" data-country-name="Uruguay">UY  -  Uruguay</option>
										<option value="UZ" data-index="226" data-phone-code="+998" data-country-id="201" data-country-name="Uzbekistan">UZ  -  Uzbekistan</option>
										<option value="VU" data-index="227" data-phone-code="+678" data-country-id="47" data-country-name="Vanuatu">VU  -  Vanuatu</option>
										<option value="VA" data-index="228" data-phone-code="+379" data-country-id="82" data-country-name="Vatican">VA  -  Vatican</option>
										<option value="VE" data-index="229" data-phone-code="+58" data-country-id="239" data-country-name="Venezuela">VE  -  Venezuela</option>
										<option value="VN" data-index="230" data-phone-code="+84" data-country-id="247" data-country-name="Vietnam">VN  -  Vietnam</option>
										<option value="WF" data-index="231" data-phone-code="+681" data-country-id="172" data-country-name="Wallis and Futuna">WF  -  Wallis and Futuna</option>
										<option value="EH" data-index="232" data-phone-code="+212" data-country-id="253" data-country-name="Western Sahara">EH  -  Western Sahara</option>
										<option value="YE" data-index="233" data-phone-code="+967" data-country-id="107" data-country-name="Yemen">YE  -  Yemen</option>
										<option value="ZM" data-index="234" data-phone-code="+260" data-country-id="254" data-country-name="Zambia">ZM  -  Zambia</option>
										<option value="ZW" data-index="235" data-phone-code="+263" data-country-id="243" data-country-name="Zimbabwe">ZW  -  Zimbabwe</option>
									</select>
								</div>
								<span class="prefix_code">+91</span>
							</div> */}
							<input
								required
								type="tel"
								name="phone"
								placeholder="e.g. 9087654367"
								id="inputbox"
								class="open_form_email"
							/>
						</div>
						<div class="form-group">
							<input
								required
								name="email"
								class="open_form_email"
								placeholder="e.g. Your email id*"
								type="text"
								id="inputbox"
							/>
						</div>
						<input name="code" class="code" type="hidden" value="+1" />
						<button
							type="submit"
							value="CONTACT US"
							class="btn btn-md btn-custom btn-enroll">
							CONTACT US
						</button>
					</form>
				</div>
			</div>
			<ToastContainer />
		</section>
	)
}

export default Section8
