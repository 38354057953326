import React from 'react'
import "../../Assests/Styles/Sections/Section7.css"

const Section7 = () => {
    return (
        <section class="courseScope pb-4">
            <div class="container learn">
                <h2 class="pt-3 pb-3 ml-3">WHAT YOU WILL LEARN</h2>
                <div class="hidden-xs">
                    <div class="row">
                        <ul class="mx-auto col-lg-12">
                            <li>
                                1. Become familiar with the basics
                                <p>Discover the basic concepts of ReactJS's modern features.</p>
                            </li>
                            <li>
                                2.	React: An Introduction
                                <p>Set up, run, and debug a React app, and understand how it works</p>
                            </li>
                            <li>
                                3.	JSX templating
                                <p>Recognize the importance of JSX with ReactJS and its syntax and features</p>
                            </li>
                        </ul>
                    </div>
                    <div class="row">
                        <ul class="mx-auto col-lg-12">
                            <li>
                                4. React Components
                                <p>Compose UI components back into UI by understanding the component architecture</p>
                            </li>
                            <li>
                                5. Rendering list
                                <p>Learn how to build ReactJS components that render lists iteratively using the map function.</p>
                            </li>
                            <li>
                                6. Event handling
                                <p>Learn how ReactJS's synthetic event system works to help you handle events.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="row">
                        <ul class="col-lg-8">
                            <li>
                                7. React Redux
                                <p>Learn how to integrate React Redux into React applications.</p>
                            </li>
                            <li>
                                8. Hooks Concepts
                                <p>Understand hooks to implement it and how it affects functional components.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section7
