import React, { useState } from 'react'
import "../../Assests/Styles/Sections/Section11.css"
import exam from "../../Assests/Images/exam1.png"
import team from "../../Assests/Images/team2.png"
import interview1 from "../../Assests/Images/interview1.png"
import computer from "../../Assests/Images/computer.png"
import man from "../../Assests/Images/man.png"
import code from "../../Assests/Images/code.png"
import book from "../../Assests/Images/bookRead.png"
import recruitment from "../../Assests/Images/recruitment.png"
import team1 from "../../Assests/Images/team1.png"

const Section11 = () => {
    const featuresData = [
        {
            title: "DOING IS LEARNING",
            img: exam,
            paragraph: "The course combines theoretical learning, hands-on exercises, group discussions, assignments, and intensive Q&A sessions.",
            background: "#2ab0d8db"
        },
        {
            title: "LIVE & INTERACTIVE",
            img: team,
            paragraph: "Participate in discussions with instructors and other participants by asking questions and getting clarifications.",
            background: "#d82a4edb"
        },
        {
            title: "EXPERTS IN THE INDUSTRY MENTOR YOU",
            img: interview1,
            paragraph: "Mentored by industry practitioners with more than 10 years of experience.",
            background: "#752ad8ab"
        },
        {
            title: "LEARNING BASED ON REASONS",
            img: computer,
            paragraph: "Gain a deeper understanding of a subject by understanding the WHAT, WHY, and HOW of it. Simplify the subject matter and gain a deeper understanding.",
            background: "#2a37d8bd"
        },
        {
            title: "PROFESSIONAL REVIEW OF CODE",
            img: man,
            paragraph: "Professional developers will review and provide timely feedback on your assignments and projects.",
            background: "#ff8507c4"
        },
        {
            title: "BUILD PROJECTS",
            img: code,
            paragraph: "Through our training, we emphasize learning concepts through examples and help you build a portfolio of projects.",
            background: "#d82a9bcf"
        },
        {
            title: "LIFETIME ENROLMENT",
            img: team1,
            paragraph: "Free lifetime enrolment into any of the upcoming batches to help you refresh the concepts.",
            background: "#2ab0d8db"
        },
        {
            title: "EXPERT DESIGNED CURRICULUM",
            img: recruitment,
            paragraph: "In order to keep the topics/modules relevant to everyday technological changes, the Curriculum goes through multiple levels of design and preparation..",
            background: "#d82a4edb"
        },
        {
            title: "YOU CAN STUDY FROM ANYWHERE",
            img: book,
            paragraph: "With the help of instructors and other participants, you'll learn how to use collaborative mediums to share opinions and improve your coding skills.",
            background: "#ff8507c4"
        },
    ]
    const [features, setFeatures] = useState(featuresData)
    return (
        <section id="features" class="clp-section-custom pt-4">
            <div class="container">
                <div class="p-0 box_heading_section-greens">
                    <div class="box_title_bx p-0">
                        <h2 class="title_box_heading_custom mb-4">DLK CAREER DEVELOPMENT EXPERIENCE</h2>
                    </div>
                </div>
                <div class="row p-md-0 p-3">
                    {features.map((data) => {
                        return (
                            <div class="col-lg-4 col-md-4">
                                <div class="feature-container">
                                    <div class="promo-content">
                                        <div class="col-2 feature-box" style={{ background: data.background }}>
                                            <i class="fa fa-laptops logobox">
                                                <img src={data.img} />
                                            </i>
                                        </div>
                                        <div class="col-10 feature-content">
                                            <h4>
                                                <span>{data.title}</span>
                                            </h4>
                                            <p>{data.paragraph}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}                   
                </div>
            </div>
        </section>
    )
}

export default Section11
