import './App.css';
import Headers from './Components/MainSource/Header/Headers';
import NavBar from './Components/MainSource/NavBar/NavBar';
import Section1 from './Components/MainSource/Sections/Section1';
import call from "./Components/Assests/Images/call-now.gif"
import whatsapp from "./Components/Assests/Images/whatsapp.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div>
      <div class="jsn-air-balloon request_quote">
        <a
          href="https://api.whatsapp.com/send?phone=+91 7299951536&amp;text=Hi! I Would Like To Know More About Your Service." target="_blank">
          <img src={whatsapp}
            alt="DLK Career Development Whatsapp"
            title="DLK Career Development Whatsapp"
          />
        </a>
      </div>
      <div class="jsn-air-balloon-phone request_quote_phone">
        <a href="tel:+91-7299951536 " title="+91-7299951536 ">
          <img src={call} alt="DLK Career Development call" title="DLK Career Development call" />
        </a>
      </div>
      <section>
        <Headers />
      </section>
      <section>
        <NavBar />
      </section>
      <Section1 />
      <ToastContainer />
    </div>
  );
}

export default App;
