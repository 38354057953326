import React from 'react'
import "../../Assests/Styles/Headers/Headers.css"
import { FaAward } from "react-icons/fa"

const Headers = () => {
  return (
    <header className='fixednav'>
      <div className='top-menu oracle-banner'>
        <div className='container'>
          <div className='row'>
            <div className='col col-12'>
              <ul class="col-4 social-left">
                <li>
                  <a href="javascript:void(0)" class="">
                    <FaAward /> 30% Discount on All Courses
                  </a>                 
                </li>
              </ul>
              <ul class="col-4 social-left d-flex justify-content-center">
                <li>                  
                  <a href="javascript:void(0)" class="">
                    Get 100% Placement Support
                  </a>
                </li>
              </ul>
              <ul class="col-4 contact-rigth d-flex justify-content-end">
                <li>
                  <a href="tel:+91-7299951536" title="+91-7299951536">
                    <span class="fas fa-phone">
                      {/* <FaPhoneAlt /> */}
                    </span>
                    <span>
                      +91-7299951536
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Headers
