import React from 'react'
import "../../Assests/Styles/Sections/Section6.css"
import preReq from "../../Assests/Images/slider.webp"

const Section6 = () => {
    return (
        <section id="preReq" class="pt-5 pb-4">
            <div class="container">
                <h2 class="mb-4 pretitle">PREREQUISITES</h2>
                <div class="row">
                    <div class="col-lg-7 Subtitle">
                        <h3>It is necessary to have prior knowledge/training</h3>
                        <ul class="contentList">
                            <li>Knowledge of JavaScript (ES6+) at an intermediate level</li>
                            <li>Basics of HTML and CSS</li>
                        </ul>
                        <h3>Software Requirements</h3>
                        <ul class="contentList">
                            <li>Node.js v13.x.x or above</li>
                            <li>IDEs or code editors such as Microsoft VSCode, Sublime Text, etc.</li>
                            <li>Use a web browser such as Google Chrome, Microsoft Edge, or Firefox</li>
                        </ul>
                        <h3>System Requirements</h3>
                        <ul class="contentList">
                            <li>A workstation or laptop with at least 8 GB of RAM is required</li>
                        </ul>
                    </div>
                    <div class="col-lg-4">
                        <img src={preReq} style={{ width: "100%", height: "auto" }} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section6
