import React from 'react'
import midBanner from "../../Assests/Images/bannerJs.png"

const Mid_Banner = () => {
  return (
    <div className='d-md-flex d-none'>
      <img src={midBanner}/>
    </div>
  )
}

export default Mid_Banner
