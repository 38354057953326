import React from 'react'
import "../../Assests/Styles/Sections/Section17.css"

const Section17 = () => {
  return (
    <section class="course-body" >
			<div class="container">				
				<div class="margin10"></div>
				<div class="clp-section">
					<h2 class="mb-0 trendingTitle" title="Find Software Testing Training in Other Cities ">                                                
						Trending Courses                        
					</h2>
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding box_heading_section">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding">
						</div>
					</div>
					<div class="margin10"></div>
					<div class="citieslink-greens">
						<ul>
							<li><a rel="" href="https://bestadvancedjava.training-in-chennai.co.in/" target="_blank" title="Advanced Java">Advanced Java</a></li>
							<li><a rel="" href="https://bestadvancedpython.training-in-chennai.co.in/" target="_blank" title="Python Training">Python</a></li>
							<li><a rel="" href="https://training-in-chennai.co.in/asp-dot-net-training-in-chennai/" target="_blank" title="ASP.NET Training">ASP.NET</a></li>
							<li><a rel="" href="https://training-in-chennai.co.in/matlab-training-in-chennai/" target="_blank" title="MatLab Training">MatLab</a></li>
							<li><a rel="" href="https://training-in-chennai.co.in/php-training-in-chennai/" target="_blank" title="Php Training">Php</a></li>
							<li><a rel="" href="https://training-in-chennai.co.in/big-data-training-in-chennai/" target="_blank" title="BigData Training">BigData</a></li>
							<li><a rel="" href="https://training-in-chennai.co.in/oracle-training-in-chennai/" target="_blank" title="Oracle Training">Oracle</a></li>
							<li><a rel="" href="https://training-in-chennai.co.in/android-training-in-chennai/" target="_blank" title="Android Training">Android</a></li>
							<li><a rel="" href="https://training-in-chennai.co.in/cloud-computing-training-in-chennai/" target="_blank" title="Cloud computing Training">Cloud</a></li>
							<li><a rel="" href="https://training-in-chennai.co.in/robotics-training-in-chennai/" target="_blank" title="Robotics Training">Robotics</a></li>
						</ul>
					</div>
				</div>
			</div>
		</section>
  )
}

export default Section17
