import React, { useState, useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import { FaLaptop } from "react-icons/fa"
import "../../Assests/Styles/Sections/Section4.css"
import ModalPage from '../ModalPage/ModalPage'

const Section4 = () => {
    const form = useRef();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_doyrc1k',
            'template_z931jd2',
            form.current,
            'W1HyUnWrLPukftHta'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                setShow(false);
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };
    return (
        <section id="usdinr" class="container mb-5 mt-5">
            <div class="row">
                <div class="col-lg-6 p-md-2 p-3">
                    <div class="promo-container">
                        <div class="promo-content">
                            <div class="promo-content-1">
                                <i class="fa fa-user">
                                    {/* <FaUserAlt /> */}
                                </i>
                            </div>
                            <div class="promo-content-2">
                                <h3>One to One Training</h3>
                                <p>Learn 1-to-1 online with a live instructor</p>
                            </div>
                            <div class="promo-content-3">
                                <div class="promo-fee">
                                    {/* Course Price at: <span data-value="9000" class="pricing">INR 16,000</span>  */}
                                </div>
                                <div class="promo-fee-discount">
                                    {/* Discount Price: <span data-value="9000" class="pricing">INR 16,000</span>  */}
                                </div>
                                <div class="promo-enroll">
                                    <input onClick={handleShow} type="submit" name="submit" value="CONTACT US" class="btn btn-md btn-custom" title="CONTACT US" />
                                </div>
                                <ModalPage
                                    handleClose={handleClose}
                                    show={show}
                                    sendEmail={sendEmail}
                                    form={form}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 p-md-2 p-3">
                    <div class="promo-container">
                        <div class="promo-content">
                            <div class="promo-content-1">
                                <i class="fa fa-laptops">
                                    <FaLaptop />
                                </i>
                            </div>
                            <div class="promo-content-2">
                                <h3>Online Classroom</h3>
                                <p>Attend our Instructor Led Online Virtual Classroom</p>
                            </div>
                            <div class="promo-content-3">
                                <div class="promo-fee">
                                    {/* <!--		Course Price at: <span data-value="15,000" class="pricing">INR 13,000</span> --> */}
                                </div>
                                <div class="promo-fee-discount">
                                    {/* <!--		Discount Price: <span data-value="15,000" class="pricing">INR 13,000</span> --> */}
                                </div>
                                <div class="promo-enroll">
                                    <input onClick={handleShow} type="submit" name="submit" value="CONTACT US" class="btn btn-md btn-custom" data-toggle="modal" data-target="#exampleModal" title="CONTACT US" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section4
