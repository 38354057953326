import React, { useState, useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import "../../Assests/Styles/Sections/Section1.css"
import { FaStar } from "react-icons/fa"
import { FiPlay } from "react-icons/fi"
import { TiTick } from "react-icons/ti"
import reactjs from "../../Assests/Images/react.png"
import { Collapse } from 'antd';
import Section2 from './Section2'
import $ from "jquery"
import Section3 from './Section3'
import Section4 from './Section4'
import Section5 from './Section5'
import Section6 from './Section6'
import Section7 from './Section7'
import Section8 from './Section8'
import Section9 from './Section9'
import Section10 from './Section10'
import Section11 from './Section11'
import Section12 from './Section12'
import Section13 from './Section13'
import Section14 from './Section14'
import Section15 from './Section15'
import Section16 from './Section16'
import Mid_Banner from './Mid_Banner'
import Section17 from './Section17'
import ModalPage from '../ModalPage/ModalPage';
import Section18 from './Section18';
const { Panel } = Collapse;

const Section1 = () => {
  const form = useRef();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onChange = (key) => {
    console.log(key);
  };
  const [navbar, setNavbar] = useState(false)

  const changeBackground = () => {
    if (window.scrollY > 610) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }
  window.addEventListener('scroll', changeBackground)
  $(window).scroll(function () {
    if ($(this).scrollTop() > 610) { $('#fixed_navigation_bar').addClass('stick'); } else { $('#fixed_navigation_bar').removeClass('stick'); }
    if ($(this).scrollTop() > 800) { $('#sticky_btn_show').removeClass('hide'); } else { $('#sticky_btn_show').addClass('hide'); }
  });

  const sendEmail = (e) => {
    e.preventDefault();
    console.log("Sending Mail....");
    emailjs.sendForm(
      'service_doyrc1k',
      'template_z931jd2',
      form.current,
      'W1HyUnWrLPukftHta'
    )
      .then((result) => {
        console.log(result.text);
        toast.success("Message Sent Successfully! We will get back to you soon.");
        e.target.reset();
        setShow(false);
        console.log("message sent");
      }, (error) => {
        toast.error("Message Sending Failed! Please try again.")
        console.log(error.text);
      });
  };

  return (
    <>
      <section className='course-banner'>
        <div className='container'>
          <div className="row">
            <div className='col-lg-8'>
              <div class="course-banner-title">
                <h1 title="Oracle Training in Chennai" style={{ marginTop: "30px" }}>ReactJS Training in Chennai </h1>
                <p class="breadcrumbs">
                  <i class="fa fa-home" aria-hidden="true">
                  </i>
                  &gt;&gt; Courses &gt;&gt; Training Courses in Chennai &gt;&gt; ReactJS Training in Chennai
                </p>
                <a class="masterprgm">
                  <i class="fas fa-laptop">
                  </i>
                  Master Program
                </a>
                <div class="course_rating">
                  <span>4.9</span>
                  <span className='starIcon'>
                    <i class="fas fa-stars">
                      <FaStar />
                    </i>
                  </span>
                  <span>4980 Ratings</span>
                  <span style={{ marginLeft: "5px" }}>
                    <span style={{ fontsize: "15px", marginLeft: "5px", marginRight: "4px" }}>10k</span>
                    + Learners
                  </span>
                </div>
                <p class="course-desc">
                  <strong>Learn to build Single Page Applications with ReactJS.</strong>
                </p>
                <ul class="course-desc">
                  <li><TiTick className='tick' /> A 32-hour instructor-led course</li>
                  <li><TiTick className='tick' /> Lifetime access to 82 hours of self-paced online learning</li>
                  <li><TiTick className='tick' /> For maximum learning benefits, we combine online self-learning with instructor-led sessions</li>
                  <li><TiTick className='tick' /> Develop web applications and SPAs that are performant, interactive, and data-driven</li>
                  <li><TiTick className='tick' /> Become familiar with Hooks, Routing, Code Splitting, and Isomorphic React</li>
                  <li><TiTick className='tick' /> Free access to 100+ E-learning courses</li>
                </ul>
                <div style={{ position: "relative", marginTop: "20px" }} class="d-flex flex-md-row flex-column">
                  <div className='col-md-4 col-lg-4 col-12 quote-btnMain'>
                    <a onClick={handleShow} class="quote-btn btn-style-one">VIEW SCHEDULES</a>
                  </div>
                  <ModalPage
                    handleClose={handleClose}
                    show={show}
                    sendEmail={sendEmail}
                    form={form}
                  />
                  <div className='col-md-4 col-lg-4 col-12 quote-btnMain'>
                    <a onClick={handleShow} class="quote-btn quote-btnCustom ">CONTACT COURSE ADVISOR</a>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="col-lg-4 angVideoWidget" style={{ marginTop: "40px" }}>
              <div class="video-widget m-2">
                <div class="video_popup">
                  <a href="#" data-toggle="modal" data-target="#enqModal" data-modalnewtitle="Get Free Demo Class Video" data-gaeventcate="leadModalOpen" data-gaeventlabel="videoRequestSidebar">
                    <img src={reactjs} class="img-fluid" alt="Video" title="Big Data Masters Program" style={{ height: "180px" }} />
                    <div class="play_btn">
                      <div style={{ textAlign: "center" }}>
                        <i class="far fa-plays playbtn hvr-pulse-grow">
                          <FiPlay style={{ fontSize: "25px", marginTop: "5px" }} />
                        </i>
                      </div>
                    </div>
                  </a>
                </div>
                <div title="Online Classroom Training">
                  <div class="card">
                    <Collapse defaultActiveKey={['']} onChange={onChange}>
                      <Panel showArrow={false} header="Online Classroom Training" key="1">
                        <ul className='listed-style'>
                          <li class="pointer"><a href="javascript: void(0);">Beginner's Oracle training with placements</a></li>
                          <li class="pointer"><a href="javascript: void(0);">Projects with Oracle Advanced</a></li>
                          <li class="pointer"><a href="javascript: void(0);">Training on Oracle and DAC</a></li>
                          <li class="pointer"><a href="javascript: void(0);">Training on PowerCenter Data Integration</a></li>
                          <li class="pointer"><a href="javascript: void(0);">Training in Data Quality</a></li>
                          <li class="pointer"><a href="javascript: void(0);">Training in Master Data Management (MDM)</a></li>
                          <li class="pointer"><a href="javascript: void(0);">Training in Informatica Big Data</a></li>
                          <li class="pointer"><a href="javascript: void(0);">Training on Axon Data Governance</a></li>
                          <li class="pointer"><a href="javascript: void(0);">Training on cloud computing (iPaaS)</a></li>
                        </ul>
                      </Panel>
                      <Panel showArrow={false} header="Certification Training" key="2">
                        <ul className='listed-style'>
                          <li class="pointer"><a href="javascript: void(0);">Certification for PowerCenter Data Integration</a></li>
                          <li class="pointer"><a href="javascript: void(0);">Certification for Data Quality</a></li>
                          <li class="pointer"><a href="javascript: void(0);">Certification in Master Data Management</a></li>
                          <li class="pointer"><a href="javascript: void(0);">Certification in Informatica Big Data</a></li>
                          <li class="pointer"><a href="javascript: void(0);">Certification in Axon Data Governance</a></li>
                          <li class="pointer"><a href="javascript: void(0);">Certification for cloud services (iPaaS)</a></li>
                        </ul>
                      </Panel>
                    </Collapse>
                  </div>
                </div>
                <div class="row haveQuery">
                  <div class="col-md-12 col-sm-12">
                    <p class="quotes">Have Queries? Ask our Experts</p>
                    <div class="overallquotes">
                      <div class="quote_left">
                        <i class="fa fa-phone-square" aria-hidden="true">
                        </i>
                      </div>
                      <div class="quote_right px-2">
                        <p class="contact"><a href="tel:+91-8939915577">+91-7299951536</a></p>
                        <span style={{ fontSize: "14px" }}> Available 24x7 for your queries</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-4 d-md-flex d-none">
              <ul class="courseFeatures clearfix">
                <li>
                  <h3>15<span>+ Hrs</span></h3>
                  <p>Lab facilities</p>
                </li>
                <li>
                  <h3>2<span>Live</span></h3>
                  <p>Projects for hands-on learning</p>
                </li>
                <li>
                  <h3>50<span>Hrs</span></h3>
                  <p>Practical assignments</p>
                </li>
                <li>
                  <h3>24<span>/7</span></h3>
                  <p>Lifetime access to support team</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="sticky_greens_navbx hidden-md hidden-sm hidden-xs">
        <div id="fixed_navigation_bar" className={navbar ? "col-lg-12 col-md-12 col-sm-12 col-xs-12 nav_course p-0" : "col-lg-12 col-md-12 col-sm-12 col-xs-12 nav_course p-0 stick"}>
          <div class="container">
            <div className='row'>
              <div class="col-lg-9 col-md-8 col-sm-12 col-xs-12 p-0">
                <nav class="fixed_nav">
                  <ul class="variableslider">
                    <li>
                      <a class="" href="#promo-container">
                        <h2>Course Formats</h2>
                      </a>
                    </li>
                    <li>
                      <a href="#prerequisites">
                        <h2>Prerequisites</h2>
                      </a>
                    </li>
                    <li>
                      <a href="#curriculum">
                        <h2>Curriculum</h2>
                      </a>
                    </li>
                    <li>
                      <a href="#reviews">
                        <h2>Reviews</h2>
                      </a>
                    </li>
                    <li>
                      <a href="#related-course">
                        <h2>Trending Courses</h2>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div id="sticky_btn_show" class="col-lg-3 col-md-4 col-sm-12 col-xs-12 no-padding hidden-xs hidden-sm stick_nav_clp text-right">
                <a class="btn btn-md btn-custom btn-fixed" onClick={handleShow} >CONTACT US</a>
              </div>
            </div>
            <div>
            </div>
          </div>
        </div>
      </section>

      <Section2 />
      <Section18/>
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
      <Section10 />
      <Mid_Banner />
      <Section11 />
      <Section12 />
      <Section13 />
      <Section14 />
      <Section17 />
      <Section15 />
      <Section16 />
      
    </>
  )
}

export default Section1
