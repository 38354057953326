import React from 'react'
import "../../Assests/Styles/Sections/Section13.css"
import { AiFillCheckCircle } from "react-icons/ai"

const Section13 = () => {
    return (
        <section class="courseDetail mockInterview">
            <div class="container ">
                <h2 class="mb-4 headerTitle" >MOCK INTERVIEWS</h2>
                <div class="row">
                    <div class="col-lg-9">
                        <ul>
                            <li><AiFillCheckCircle className='whoShouldAtnTick' />DLK Career Development offers you the opportunity to prepare, practice, and experience a real-life job interview. Familiarizing yourself with the interview environment beforehand in a relaxed and stress-free environment gives you an edge over your competitors.</li>
                            <li><AiFillCheckCircle className='whoShouldAtnTick' />During our mock interviews, you'll be interviewed by industry experts with an average of 5+ years of experience. So you're sure to increase your chances of getting hired!</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section13
