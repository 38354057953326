import React, { useState, useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import "../../Assests/Styles/Sections/Section15.css"
import ModalPage from '../ModalPage/ModalPage'


const Section15 = () => {
    const form = useRef();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_doyrc1k',
            'template_z931jd2',
            form.current,
            'W1HyUnWrLPukftHta'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                setShow(false);
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };
    return (
        <footer class="new_footer_area bg_color p-md-0 p-3 mb-4">
            <div class="anim-icons"><span class="icon icon-paper-clip bounce-x"></span></div>
            <div class="new_footer_top">
                <div class="container">
                    <div class="row">
                        <div className='linkWithCard'>
                            <div className='container '>
                                <div className='container '>
                                    <div className='container '>
                                        <div class="col col-md-12 row text-center d-flex justify-content-center  pt-5">
                                            <div class="col-md-2">
                                                <h6 class="text-uppercase font-weight-bold ">
                                                    <a onClick={() => window.open("https://training-in-chennai.co.in/about-us/")} class="text-decoration-none line navTitle">About us</a>
                                                </h6>
                                            </div>
                                            <div class="col-md-2">
                                                <h6 class="text-uppercase font-weight-bold ">
                                                    <a onClick={() => window.open("https://training-in-chennai.co.in/DLK%20CD%20Brochure%20Flipbook/DLK%20CD%20Brochure%20Flipbook/DK%20CD%20Brochure.html")} class="navTitle text-decoration-none line">Profile</a>
                                                </h6>
                                            </div>
                                            <div class="col-md-2">
                                                <h6 class="text-uppercase font-weight-bold ">
                                                    <a onClick={() => window.open("https://training-in-chennai.co.in/courses/")} class="text-decoration-none line navTitle">Courses</a>
                                                </h6>
                                            </div>
                                            <div class="col-md-2">
                                                <h6 class="text-uppercase font-weight-bold ">
                                                    <a href="https://training-in-chennai.co.in/DLK%20CD%20Portfolio/DLK%20CD%20Portfolio/mobile/index.html" class="text-decoration-none line navTitle">Portfolio</a>
                                                </h6>
                                            </div>

                                            <div class="col-md-2">
                                                <h6 class="text-uppercase font-weight-bold ">
                                                    <a href="https://realtimeproject.in/blog/" class="text-decoration-none line navTitle">Blog</a>
                                                </h6>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="my-4" />
                            <div class="row d-flex justify-content-center ">
                                <div class="col-lg-8 mb-5 p-md-0 p-4">
                                    <div className='col col-md-12 d-flex justify-content-center'>
                                        <text className='footerTitle'>Are You Located In Any Of These Areas?</text>
                                    </div>
                                    <p className='mt-4 footerPara'>
                                        Adyar, Adambakkam, Anna Salai, Ambattur, Ashok Nagar, Aminjikarai, Anna Nagar,
                                        Besant Nagar, Chromepet, Choolaimedu, Guindy, Egmore, K.K. Nagar, Kodambakkam,
                                        Ekkattuthangal, Kilpauk, Medavakkam, Nandanam, Nungambakkam, Madipakkam, Teynampet,
                                        Nanganallur, Mylapore, Pallavaram, OMR, Porur, Pallikaranai, Saidapet, St.Thomas Mount,
                                        Perungudi, T.Nagar, Sholinganallur, Triplicane, Thoraipakkam, Tambaram, Vadapalani,
                                        Villivakkam, Thiruvanmiyur, West Mambalam, Velachery and Virugambakkam.
                                    </p>
                                    <span className='mt-4 footerPara'>
                                        Our DLK Career Development CDC Vadapalani office is just few kilometre away from your location.
                                        If you need the best practical training in Chennai, driving couple of extra kilometres is worth it!.
                                    </span>
                                </div>
                                <div className='col col-md-12 col-12 watchNow mb-5'>
                                    <button type="button" class="started"
                                        onClick={handleShow}
                                    >ENQUIRY NOW</button>
                                </div>
                                <ModalPage
                                    handleClose={handleClose}
                                    show={show}
                                    sendEmail={sendEmail}
                                    form={form}
                                />
                            </div>
                            <ToastContainer />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Section15
