import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "../../Assests/Styles/ModalPage/ModalPage.css"
import ReCAPTCHA from "react-google-recaptcha";


const ModalPage = ({ show, handleClose, form, sendEmail }) => {
    const recaptchaRef = React.createRef();
    function onChange(value) {
        console.log("Captcha value:", value);
        // setIsModalOpen(true)
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <h5 className='modal-title-custom'>Get Course Fees & Discounts</h5>
            </Modal.Header>
            <Modal.Body>
                <form ref={form} onSubmit={sendEmail}>
                    <div class="col-md-12 col-sm-12">
                        <input
                            required
                            type="text"
                            name="name"
                            placeholder="Name"
                            id="inputbox"
                            class="form-control quick-enquiry-form"
                        />
                    </div>
                    <div class="col-md-12 col-sm-12 mt-3">
                        <input
                            required
                            type="tel"
                            name="phone"
                            placeholder="Phone"
                            class="form-control quick-enquiry-form"
                            id="inputbox"
                        />
                    </div>
                    <div class="col-md-12 col-sm-12 mt-3">
                        <input
                            required
                            type="email"
                            name="email"
                            placeholder="Email"
                            class="form-control quick-enquiry-form"
                            id="inputbox"
                        />
                    </div>
                    <div class="col-md-12 col-sm-12 mt-3">
                        <textarea
                            class="form-control quick-enquiry-form"
                            rows="2"
                            id="textarea"
                            name="message"
                            placeholder='Message'
                        ></textarea>
                    </div>
                    <div className='d-flex justify-content-center col-12 mt-2'>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6LebJmAnAAAAAOo9VyEgHf5foNyzGoxQDdE9UuCf"
                            onChange={onChange}
                        />
                    </div>
                    <div class="margin10"></div>
                    <div class="col-md-12 col-sm-12">
                        <button type="submit" class="btn btnlg btn-primary-custom">I'm Interested, Tell me more</button>
                    </div>
                    <div class="row mb-3" style={{ marginTop: "20px", textAlign: "center" }}>
                        <div class="col-md-12 col-sm-12">
                            <p class="quotes">Have Queries? Ask our Experts</p>
                            <div class="overallquotes">
                                <div class="quote_left">
                                    <i class="fa fa-phone-square" aria-hidden="true"></i>
                                </div>
                                <div class="quote_right">
                                    <p class="contact">
                                        <a href="tel:+91-7299951536">+91-7299951536</a>
                                    </p>
                                    <span style={{ fontSize: "14px" }}> Available 24x7 for your queries</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ModalPage
