import React from 'react'
import "../../Assests/Styles/Sections/Section3.css"

const Section3 = () => {
    
    return (
        <section id="OracleFeatures" class="course-body">
            <div class="container mb-4">
                <h2 class="pb-4 pt-3 m-0 featuresTitle">Benefits for individuals:</h2>
                <p>With a ReactJS certification, you can reap multiple benefits. Here are some of them:</p>
                <div class="row">
                    <ul class="contentList">
                        <li>Increase your career opportunities by working as a Full Stack Engineer, Senior Developer, or Front End Developer.</li>
                        <li>Invest in top-notch developer tools to increase your productivity.</li>
                        <li>Upgrade your skillsets by learning components and write with JSX to be relevant to the market.</li>
                        <li>ReactJS is backed by a strong developer community enabling you to grow your network.</li>
                    </ul>
                </div>
                <h2 class="pb-4 pt-3 m-0 featuresTitle">Benefits for the organization</h2>
                <p>Instagram, Facebook, AirBnB, and the list goes on and on use ReactJS due to its many benefits. Among these are:</p>
                <div class="row">
                    <ul class="contentList">
                        <li>Stabilizes the business and minimizes risks.</li>
                        <li>With its reusability, performance, migration, and SEO capabilities, you can build your project efficiently.</li>
                        <li>CSS is incorporated into HTML using ReactJS.</li>
                        <li>The Browser Developer Tools facilitate faster debugging.</li>
                        <li>It is so easy to test ReactJS because its components are so simple to break down.</li>
                        <li>With its declarative syntax and User Interface componentization, switching between technologies is simplified.</li>
                    </ul>
                </div>
            </div >
            <div id="promo-container"></div>
        </section >
    )
}

export default Section3
