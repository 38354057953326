import React, { useState } from 'react'
import "../../Assests/Styles/Sections/Section14.css"
import { AiFillStar } from "react-icons/ai"
import $ from "jquery"

const Section14 = () => {
    const [open, setOpen] = useState(false)
    const handleClick = () => {
        setOpen(true)
    }
    const handleClick1 = () => {
        setOpen(false)
    }
    return (
        <section class="reviews_section_bx" id="reviews">
            <div class="container">
                <div class="clp-section-custom col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 reviews_section_bx active">
                    <div class="Reviews_testimonial_greens ">
                        <br />
                        <h2 class="title headerTitle">Testimonial Reviews</h2>
                        <br />
                        <div>
                            <div class="review-greens-box">
                                <div class="review_img_greens">
                                    <div class="review_alpha_img review_alpha_img3"><span>A</span></div>
                                    <div class="rev_res_bx"><span class="title ">Abinaya </span><br />
                                        <span class="star-rating-review">
                                            <span class="star-rating-review">
                                                <i class="fas fa-stars yellow">
                                                    <AiFillStar />
                                                </i><i class="fas fa-stars yellow">
                                                    <AiFillStar />
                                                </i>
                                                <i class="fas fa-stars yellow">
                                                    <AiFillStar />
                                                </i>
                                                <i class="fas fa-stars yellow">
                                                    <AiFillStar />
                                                </i>
                                                <i class="fas fa-stars yellow">
                                                    <AiFillStar />
                                                </i>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="review_information_custom">
                                    <span class="review-text">
                                        <p>After completion of graduation, was very disappointed in life as I didn’t get through any campus interviews.. Then I chose DLK Career Development for learning Angular as my friend conveyed that it was THE BEST Institute. After joining them I gradually developed interest in the subject and achieved what I had dreamt of once. The whole credit goes to Mr.Karthik who taught me Angular and helped me in <strong>clearing Angular Certification</strong> .And not only that. Today I have been <strong>placed as Angular developer </strong>in one of the most reputed organizations. Ever grateful to Karthik sir and the whole team.</p>
                                    </span>
                                </div>
                            </div>
                            <div class="review-greens-box ">
                                <div class="review_img_greens">
                                    <div class="review_alpha_img review_alpha_img5"><span>S</span></div>
                                    <div class="rev_res_bx"><span class="title ">Sudha </span><br />
                                        <span class="star-rating-review">
                                            <span class="star-rating-review">
                                                <i class="fas fa-stars yellow">
                                                    <AiFillStar />
                                                </i><i class="fas fa-stars yellow">
                                                    <AiFillStar />
                                                </i>
                                                <i class="fas fa-stars yellow">
                                                    <AiFillStar />
                                                </i>
                                                <i class="fas fa-stars yellow">
                                                    <AiFillStar />
                                                </i>
                                                <i class="fas fa-stars yellow">
                                                    <AiFillStar />
                                                </i>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="review_information_custom">
                                    <span class="review-text">
                                        <p>I had opted for Reactjs training in DLK Career Development. I would suggest to take this course because when I investigated for this course online cost was bit high, but DLK Career Development offered it for better fees. And regarding the course agenda, they are very sincere and punctual. And they also provide the recorded sessions to study it offline. Though I had taken online program ,the team ensured that I completed my <strong>certification successfully and got placed .</strong></p>
                                    </span>
                                </div>
                            </div>
                            <div class="review-greens-box ">
                                <div class="review_img_greens">
                                    <div class="review_alpha_img review_alpha_img1"><span>J</span></div>
                                    <div class="rev_res_bx"><span class="title ">Joncy </span><br />
                                        <span class="star-rating-review">
                                            <span class="star-rating-review">
                                                <i class="fas fa-stars yellow">
                                                    <AiFillStar />
                                                </i><i class="fas fa-stars yellow">
                                                    <AiFillStar />
                                                </i>
                                                <i class="fas fa-stars yellow">
                                                    <AiFillStar />
                                                </i>
                                                <i class="fas fa-stars yellow">
                                                    <AiFillStar />
                                                </i>
                                                <i class="fas fa-stars yellow">
                                                    <AiFillStar />
                                                </i>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="review_information_custom">
                                    <span class="review-text">
                                        <p>ReactJS Training was excellent, with Karthik Sir sharing a lot of scenarios and covering the concepts .The training center was good and well maintained. Would recommend DLK Career Development for Angular Training for sure.. Also, placement team was very supportive and prompt in sharing the required details. <strong>Thanks to the whole team for helping me to clear my certification and also to get placed as Angular developer</strong>.</p>
                                    </span>
                                </div>
                            </div>
                            {open === true &&
                                <span class="customShowMoreToggleContent">
                                    <div class="review-greens-box ">
                                        <div class="review_img_greens">
                                            <div class="review_alpha_img review_alpha_img5"><span>S</span></div>
                                            <div class="rev_res_bx"><span class="title ">Sanjay kumar</span><br />
                                                <span class="star-rating-review">
                                                    <span class="star-rating-review">
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i><i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="review_information_custom">
                                            <span class="review-text">
                                                <p>I learnt React program &nbsp;in DLK Career Development...Way of Teaching is easy to understand. Class isHelpful and efficient. I am very happy that Trainer is willing to Clarify all my doubts ... I did a <strong>certification</strong> too and I'd say it's worth the penny as I am settled now because <strong>I got placed too</strong>. The trainers are well experienced and the teaching is very good. Staffing and environment is also good.</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="review-greens-box ">
                                        <div class="review_img_greens">
                                            <div class="review_alpha_img review_alpha_img3"><span>T</span></div>
                                            <div class="rev_res_bx"><span class="title ">Thilak</span><br />
                                                <span class="star-rating-review">
                                                    <span class="star-rating-review">
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i><i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="review_information_custom">
                                            <span class="review-text">
                                                <p>DLK Career Development.has a peaceful place and &nbsp;the trainers are so reliable and friendly. You can learn things with ease and timings are so flexible. Awesome institution to learn. The staffs have real time experience which help us to learn alot apart from syllabus and we get to know about the issues and solutions. I was a fresher with &nbsp;zero knowledge when I entered DLK Career Development, now I can confidently code and solve any issues and got certification too and <strong>Yes, I already got &nbsp;an</strong> <strong>offer letter &nbsp;from Wipro for the role of an Angular developer</strong>. So Wish me Luck!!!!Thanks to the whole team of DLK Career Development.</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="review-greens-box ">
                                        <div class="review_img_greens">
                                            <div class="review_alpha_img review_alpha_img2"><span>S</span></div>
                                            <div class="rev_res_bx"><span class="title ">Sharmy</span><br />
                                                <span class="star-rating-review">
                                                    <span class="star-rating-review">
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i><i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="review_information_custom">
                                            <span class="review-text">
                                                <p>I Joined for DLK Career Development for ReactJs Training .Overall it was a very good Experience. The Sessions were more practical and Karthik sir,our trainer explained each concepts in detailed. He kept his lessons simple and He not only taught me the subject but also incorporated loads of confidence in me.Ever Thankful to Karthik Sir and his team.</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="review-greens-box ">
                                        <div class="review_img_greens">
                                            <div class="review_alpha_img review_alpha_img2"><span>M</span></div>
                                            <div class="rev_res_bx"><span class="title ">Mani</span><br />
                                                <span class="star-rating-review">
                                                    <span class="star-rating-review">
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i><i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="review_information_custom">
                                            <span class="review-text">
                                                <p>I have studied Angular and devops in DLK Career Development. I got a real time exposure and in depth coding skills now with the help of Karthik Sir. His classes were filled with highly technical and practical oriented. We can get the fundamentals and In-Depth of subject strong enough for sure. Thanks to Karthik sir and his team for the skills and dedication towards sessions. Thanks to DLK Career Development for making my career strong enough.</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="review-greens-box ">
                                        <div class="review_img_greens">
                                            <div class="review_alpha_img review_alpha_img5"><span>J</span></div>
                                            <div class="rev_res_bx"><span class="title ">Jaya</span><br />
                                                <span class="star-rating-review">
                                                    <span class="star-rating-review">
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i><i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="review_information_custom">
                                            <span class="review-text">
                                                <p>This is one of the best institute in Chennai. Mentors were very helpful and they have always encouraged us to learn more and more things. This institute helped me to get my first job as an Team Member in Accenture. If you are thinking about finding a job in Angular then it must be a great choice. <br /> Special Thanks To trainer and DLK Career Development Team for helping me to get job.</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="review-greens-box ">
                                        <div class="review_img_greens">
                                            <div class="review_alpha_img review_alpha_img6"><span>T</span></div>
                                            <div class="rev_res_bx"><span class="title ">Tila</span><br />
                                                <span class="star-rating-review">
                                                    <span class="star-rating-review">
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i><i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                        <i class="fas fa-stars yellow">
                                                            <AiFillStar />
                                                        </i>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="review_information_custom">
                                            <span class="review-text">
                                                <p>I was working with a leading MNC and I had to leave my job as I couldn’t upgrade myself. That’s when I knocked the doors of DLK Career Development. The whole team emphasized my situation and gave me the hope that I can definitely get a better job.</p>
                                                <p>And here I stand today as <strong>Angular Developer</strong> in a reputed MNC.I can’t thank DLK Career Development enough for helping me to up skill myself and re-join an organization with a higher Portfolio.</p>
                                            </span>
                                        </div>
                                    </div>
                                </span>
                            }
                            {open === false ?
                                <a class="customShowMoreToggle" onClick={handleClick}>
                                    <i class="fa fa-angle-down"></i>
                                    <span>Read More Testimonials</span>
                                </a>
                                :
                                <a class="customShowMoreToggle" onClick={handleClick1}>
                                    <i class="fa fa-angle-up"></i>
                                    <span>Read less Testimonials</span>
                                </a>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div id="related-course"></div>
        </section>
    )
}

export default Section14
