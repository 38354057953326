import React, { useState, useRef } from 'react'
import inr from "../../Assests/Images/inr.png"
import "../../Assests/Styles/Sections/Section16.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import ModalPage from '../ModalPage/ModalPage';

const Section16 = () => {
    const form = useRef();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_doyrc1k',
            'template_z931jd2',
            form.current,
            'W1HyUnWrLPukftHta'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                setShow(false);
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };

    return (
        <section class="footer-sticky hidden-xs hidden-sm d-flex flex-row">
            <div class="col-lg-7 center_bx_foot">
                <div class="num_card">
                    <span class="text">For Career Assistance :</span>
                    <span class="in_num">
                        <img src={inr} alt="flag_in" />
                        <a aria-label="India flag" href="tel:+91 7299951536 "> +91-7299951536 </a>
                    </span>
                </div>
            </div>
            <div class="col-lg-3 center_bx_foot" style={{ color: "white" }} onClick={handleShow}>
                <button className='insideNav-Link sendEnquiry'>Send Your Enquiry</button>
            </div>
            <ModalPage
                handleClose={handleClose}
                show={show}
                sendEmail={sendEmail}
                form={form}
            />
        </section>
    )
}

export default Section16
