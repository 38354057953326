import React, { useState, useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import "../../Assests/Styles/NavBar/NavBar.css"
import logo from "../../Assests/Images/ourlogo.png"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import ModalPage from '../ModalPage/ModalPage';

const NavBar = () => {
    const form = useRef();
    const [show, setShow] = useState(false);
    const [navbar, setNavbar] = useState(false)
    

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_doyrc1k',
            'template_z931jd2',
            form.current,
            'W1HyUnWrLPukftHta'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                setShow(false);
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };

    const changeBackground = () => {
        if (window.scrollY >= 90) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }
    window.addEventListener('scroll', changeBackground)
    return (
        <div id="navbar" className={navbar ? 'navbar-area   insideNav ' : 'navbar-area insideNav  mt-6'}>
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                <Container>
                    <Navbar.Brand href="#home">
                        <img
                            src={logo}
                            width="150"
                            className="d-inline-block align-top"
                            alt="React Bootstrap logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className='col-12 d-flex justify-content-end'>
                            <Nav.Link onClick={() => window.open("https://training-in-chennai.co.in/corporatetraining/")} className='insideNav-Link'>Corporate Training</Nav.Link>
                            <Nav.Link onClick={() => window.open("https://training-in-chennai.co.in/courses/")} className='insideNav-Link'>All Courses</Nav.Link>
                            <Nav.Link onClick={() => window.open("https://training-in-chennai.co.in/DLK%20CD%20Portfolio/DLK%20CD%20Portfolio/mobile/index.html")} className='insideNav-Link'>Portfolio</Nav.Link>
                            <Nav.Link onClick={handleShow} className='insideNav-Link'>Contact Us</Nav.Link>
                            <ModalPage
                               handleClose={handleClose}
                               show={show}
                               sendEmail={sendEmail}
                               form={form}
                            />
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default NavBar
